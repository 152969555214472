// Required during insertion if we have not set the keys it will add to the payload
export const insertDefaultPayLoad = {
  location: '',
  deleted: false,
  client_id: null,
}

export const getLocations = `query getLocations($query: LocationQueryInput, $limit: Int!, $sortBy: LocationSortByInput!){
  locations(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      location
    }
  }`

export const getLocation = `query getLocation($query: LocationQueryInput){
    location(query: $query) {
      _id
      location
      zone
      agency
      client_id { _id }
    }
  }`

const query = `query locations($query: LocationQueryInput, $limit: Int, $sortBy: LocationSortByInput){
    locations(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      location
    }
  }`

// eslint-disable-next-line no-underscore-dangle
export const _getLocations = `query LocationsData($input: LocationsDatum!){
    LocationsData(input: $input) {
        totalRecords
        locations {
          _id
          location
          zone
          agency
        }
    }
  }`

export const deleteOneLocation = `mutation deleteOneLocation($query: LocationQueryInput!) {
    deleteOneLocation(query: $query) {
      _id
      location
    }
  }
  `

export const addLocation = `mutation insertOneLocation($data: LocationInsertInput!){
    insertOneLocation(data: $data) {
      _id
      location
    }
  }`

export const updateLocation = `mutation updateOneLocation($query: LocationQueryInput!, $data: LocationUpdateInput!) {
    updateOneLocation(query: $query, set: $data) {
      _id
    }
  }
  `

export default query
